<template>
  <div ref="configure" class="configure">
    <div class="header">
      <span>配置产品</span>
      <a-button style="width: 85px" type="primary" @click="$router.go(-1)"
        >返回
      </a-button>
    </div>
    <div class="box">
      <div class="select-box">
        产品名称
        <a-input
          v-model:value="search.productFyName"
          class="mt5"
          placeholder="产品名称"
        />
      </div>
      <div class="select-box">
        适用人群
        <a-select
          v-model:value="search.applyObject"
          class="mt5"
          style="width: 200px"
        >
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="不限">不限</a-select-option>
          <a-select-option value="男">男</a-select-option>
          <a-select-option value="女">女</a-select-option>
        </a-select>
      </div>
      <div class="select-box">
        是否已婚
        <a-select
          v-model:value="search.isMarrid"
          class="mt5"
          style="width: 200px"
        >
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="2">不限</a-select-option>
          <a-select-option value="1">未婚</a-select-option>
          <a-select-option value="0">已婚</a-select-option>
        </a-select>
      </div>
      <div class="select-box" style="width: 440px">
        区域
        <a-cascader
          v-model:value="searchCity"
          :options="options"
          :show-search="{ filter }"
          change-on-select
          class="mt5"
          placeholder="请选择"
          style="width: 440px"
        />
      </div>
      <div class="select-box">
        产品状态
        <a-select
          v-model:value="search.isValid"
          class="mt5"
          style="width: 200px"
        >
          <a-select-option value="">全部</a-select-option>
          <a-select-option :value="true">有效</a-select-option>
          <a-select-option :value="false">无效</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="button-box">
      <a-button
        :loading="getloading"
        style="width: 85px"
        type="primary"
        @click="getListData"
        >查询
      </a-button>
      <a-button style="width: 85px" type="primary" @click="reset"
        >重置
      </a-button>
      <a-button
        :disabled="!hasSelected"
        danger
        style="width: 85px"
        type="primary"
        @click="del"
        >移除
      </a-button>
      <a-button style="width: 115px" type="primary" @click="addProduct"
        >添加体检产品
      </a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="position"
      :row-key="(record) => record.guid"
      :row-selection="{
        selectedRowKeys: productFyGuids,
        onChange: rowSelection
      }"
      bordered
      style="margin-top: 10px"
      @change="tableChange"
    >
      <template #bodyCell="{ column, text, index, record }">
        <template v-if="column.dataIndex === 'index'">
          {{ index + 1 }}
        </template>
        <template v-if="column.dataIndex === 'isMarrid'">
          {{ marry(text) }}
        </template>
        <template v-if="column.dataIndex === 'organizations'">
          <div v-for="(item, index) in text" :key="index">
            {{ item.name }}
          </div>
        </template>
        <template v-if="column.dataIndex === 'area'">
          <div v-for="(item, index) in record.organizations" :key="index">
            {{ item.province + item.city + item.area }}
          </div>
        </template>
        <template v-if="column.dataIndex === 'isValid'">
          <span :class="{ red: !text }"> {{ text ? '有效' : '无效' }}</span>
        </template>
      </template>
    </a-table>
    <a-modal
      v-model:visible="visible"
      :footer="null"
      :getContainer="$refs.configure"
      centered
      title="添加体检产品"
      width="80%"
      @cancel="handleCancel"
    >
      <div class="box">
        <div class="select-box2">
          产品名称
          <a-input
            v-model:value="modalSearch.productFyName"
            class="mt5"
            placeholder="产品名称"
          />
        </div>
        <div class="select-box2">
          适用人群
          <a-select
            v-model:value="modalSearch.applyObject"
            class="mt5"
            style="width: 200px"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="不限">不限</a-select-option>
            <a-select-option value="男">男</a-select-option>
            <a-select-option value="女">女</a-select-option>
          </a-select>
        </div>
        <div class="select-box2">
          是否已婚
          <a-select
            v-model:value="modalSearch.isMarrid"
            class="mt5"
            style="width: 200px"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="2">不限</a-select-option>
            <a-select-option value="1">未婚</a-select-option>
            <a-select-option value="0">已婚</a-select-option>
          </a-select>
        </div>
        <div class="select-box2" style="width: 360px">
          区域
          <a-cascader
            v-model:value="modalCity"
            :options="options"
            :show-search="{ filter }"
            change-on-select
            class="mt5"
            placeholder="请选择"
            style="width: 360px"
          />
        </div>
        <div class="select-box2" style="width: 260px">
          采购价
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <a-input
              v-model:value="modalSearch.lowFySellPrice"
              class="mt5"
              prefix="￥"
              style="width: 120px"
            />
            -
            <a-input
              v-model:value="modalSearch.highFySellPrice"
              class="mt5"
              prefix="￥"
              style="width: 120px"
            />
          </div>
        </div>
      </div>
      <div class="button-box" style="width: 200px">
        <a-button
          :loading="btnLoading"
          style="width: 85px"
          type="primary"
          @click="getModalData"
          >查询
        </a-button>
        <a-button style="width: 85px" type="primary" @click="modalReset"
          >重置
        </a-button>
      </div>
      <a-table
        :columns="columns2"
        :data-source="modalData"
        :loading="modalLoading"
        :pagination="modalPosition"
        :row-key="(record) => record.guid"
        :row-selection="{
          selectedRowKeys: addProductFyGuids,
          onChange: modalSelection
        }"
        bordered
        style="margin-top: 10px"
        @change="modalChange"
      >
        <template #bodyCell="{ column, text, index, record }">
          <template v-if="column.dataIndex === 'index'">
            {{ index + 1 }}
          </template>
          <template v-if="column.dataIndex === 'isMarrid'">
            {{ marry(text) }}
          </template>
          <template v-if="column.dataIndex === 'organizations'">
            <div v-for="(item, index) in text" :key="index">
              {{ item.name }}
            </div>
          </template>
          <template v-if="column.dataIndex === 'area'">
            <div v-for="(item, index) in record.organizations" :key="index">
              {{ item.province + item.city + item.area }}
            </div>
          </template>
        </template>
      </a-table>
      <div class="add-box">
        <a-button
          :disabled="!addButton"
          style="width: 85px"
          type="primary"
          @click="add"
          >提交添加
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { computed, createVNode, reactive, ref } from 'vue'
import { pageProductFY, productBinds, productUnbinds } from '@/api'
import { useRoute } from 'vue-router'
import cityData from '@/components/city.json'
import { message, Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'

const route = useRoute()
const guid = route.query.guid
// 表格数据
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    align: 'center'
  },
  {
    title: '产品编码',
    dataIndex: 'productFyId',
    align: 'center'
  },
  {
    title: '产品名称',
    dataIndex: 'productFyName',
    align: 'center'
  },
  {
    title: '适用人群',
    dataIndex: 'applyObject',
    align: 'center'
  },
  {
    title: '是否已婚',
    dataIndex: 'isMarrid',
    align: 'center'
  },
  {
    title: '门店',
    dataIndex: 'organizations',
    align: 'center'
  },
  {
    title: '区域',
    dataIndex: 'area',
    align: 'center'
  },
  {
    title: '采购价',
    dataIndex: 'sellPrice',
    align: 'center'
  },
  {
    title: '产品状态',
    dataIndex: 'isValid',
    align: 'center'
  }
]
const dataSource = ref([])

//分页配置
const positionConfig = () => {
  return reactive({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ['10', '20', '30', '100'],
    total: 0,
    showTotal: (total) => `共${total}条`,
    position: ['bottomLeft']
  })
}
let position = positionConfig()

//表格选中
const productFyGuids = ref([])
const rowSelection = (selectedRowKeys) => {
  productFyGuids.value = selectedRowKeys
}
//添加体检产品modal
let visible = ref(false)
//禁止点击移除按钮(未选中时)
const hasSelected = computed(() => productFyGuids.value.length > 0)

//是否结婚计算属性
const marry = computed(() => (text) => {
  // 0已婚 1未婚 2不限
  let status
  if (text === 0) {
    status = '已婚'
  } else if (text === 1) {
    status = '未婚'
  } else if (text === 2) {
    status = '不限'
  } else {
    status = '状态有问题请联系管理员'
  }
  return status
})

//搜索条件
const formSource = () => {
  return reactive({
    pageIndex: 1,
    productFyName: '', //产品名称
    applyObject: '', //适用人群
    isMarrid: '', //是否结婚
    isValid: '', //是否有效
    province: '', //省
    city: '', //城市
    area: '' //区
  })
}
let search = formSource()
let conditionData = formSource()
//重置
const reset = () => {
  search = Object.assign(search, formSource())
  searchCity.value = []
}
//区域数据
const searchCity = ref([])
const options = cityData
//区域搜索
const filter = (inputValue, path) => {
  return path.some(
    (option) =>
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  )
}
//获取套餐产品数据
const loading = ref(false)
//查询
const getloading = ref(false)
const getInquire = (pagination) => {
  conditionData.productGuid = guid
  loading.value = true
  if (pagination) {
    conditionData.pageIndex = pagination.current
    conditionData.pageSize = pagination.pageSize
  } else {
    search.pageIndex = 1
  }
  pageProductFY(conditionData)
    .then((res) => {
      const data = res.data
      dataSource.value = data.data
      position.total = data.count
      loading.value = false
      if (pagination) {
        position.current = pagination.current
        position.pageSize = pagination.pageSize
      } else {
        position.current = 1
      }
    })
    .catch((error) => {
      loading.value = false
      Modal.error({
        content: error.response.data
      })
    })
}
//点击查询
const getListData = () => {
  getloading.value = true
  conditionData = Object.assign(conditionData, search)
  conditionData.pageSize = position.pageSize
  conditionData.province = searchCity.value[0]
  conditionData.city = searchCity.value[1]
  conditionData.area = searchCity.value[2]
  getInquire()
  getloading.value = false
}
getListData()
//分页事件
const tableChange = (pagination) => {
  getInquire(pagination)
}
//删除
const del = () => {
  Modal.confirm({
    title: '确认移除所选?',
    icon: createVNode(ExclamationCircleOutlined),
    centered: true,
    onOk() {
      productUnbinds(guid, productFyGuids.value)
        .then(() => {
          message.success('移除成功')
          productFyGuids.value = []
          getInquire()
        })
        .catch((error) => {
          Modal.error({
            content: error.response.data
          })
          productFyGuids.value = []
          getInquire()
        })
    },
    onCancel() {}
  })
}

////禁止点击移除按钮(未选中时)
const addButton = computed(() => addProductFyGuids.value.length > 0)
//打开添加体检产品modal框
const addProduct = async () => {
  await getModalData()
  visible.value = true
}
//添加体检产品--表格数据
const columns2 = [
  {
    title: '序号',
    dataIndex: 'index',
    align: 'center'
  },
  {
    title: '产品编码',
    dataIndex: 'productFyId',
    align: 'center'
  },
  {
    title: '产品名称',
    dataIndex: 'productFyName',
    align: 'center'
  },
  {
    title: '适用人群',
    dataIndex: 'applyObject',
    align: 'center'
  },
  {
    title: '是否已婚',
    dataIndex: 'isMarrid',
    align: 'center'
  },
  {
    title: '门店',
    dataIndex: 'organizations',
    align: 'center'
  },
  {
    title: '区域',
    dataIndex: 'area',
    align: 'center'
  },
  {
    title: '采购价',
    dataIndex: 'sellPrice',
    align: 'center'
  }
]
const modalData = ref([])
//添加体检产品--分页配置
const modalPosition = positionConfig()
//添加体检产品-搜索
const modalSource = () => {
  return reactive({
    pageIndex: 1,
    productFyName: '', //产品名称
    applyObject: '', //适用人群
    isMarrid: '', //是否结婚
    isValid: true, //是否有效
    province: '', //省
    city: '', //城市
    area: '', //区
    lowFySellPrice: '', //最低价
    highFySellPrice: '' //最高价
  })
}
let modalSearch = modalSource()
let modalConditionData = modalSource()
//区域选择
const modalCity = ref([])
//添加体检产品--重置
const modalReset = () => {
  modalSearch = Object.assign(modalSearch, modalSource())
  modalCity.value = []
}
//添加体检产品--选中的产品
const addProductFyGuids = ref([])
//添加体检产品--选中事件
const modalSelection = (selectedRowKeys) => {
  addProductFyGuids.value = selectedRowKeys
}
//添加体检产品--查询loading
const modalLoading = ref(false)
const getSearch = (pagination) => {
  modalConditionData.excludeProductGuid = guid
  modalLoading.value = true
  if (pagination) {
    modalConditionData.pageIndex = pagination.current
    modalConditionData.pageSize = pagination.pageSize
  } else {
    modalSearch.pageIndex = 1
  }
  pageProductFY(modalConditionData)
    .then((res) => {
      const data = res.data
      modalData.value = data.data
      modalPosition.total = data.count
      modalLoading.value = false
      if (pagination) {
        modalPosition.current = pagination.current
        modalPosition.pageSize = pagination.pageSize
      } else {
        modalPosition.current = 1
      }
    })
    .catch((error) => {
      modalLoading.value = false
      Modal.error({
        content: error.response.data
      })
    })
}
//添加体检产品--点击查询
const btnLoading = ref(false)
const getModalData = () => {
  btnLoading.value = true
  modalConditionData = Object.assign(modalConditionData, modalSearch)
  modalConditionData.pageSize = position.pageSize
  modalConditionData.province = modalCity.value[0]
  modalConditionData.city = modalCity.value[1]
  modalConditionData.area = modalCity.value[2]
  getSearch()
  btnLoading.value = false
}

//添加体检产品--点击分页查询
const modalChange = (pagination) => {
  getSearch(pagination)
}
//添加体检产品
const add = () => {
  Modal.confirm({
    title: '确认添加所选?',
    icon: createVNode(ExclamationCircleOutlined),
    centered: true,
    onOk() {
      productBinds(guid, addProductFyGuids.value)
        .then(() => {
          message.success('添加成功')
          addProductFyGuids.value = []
          getSearch()
        })
        .catch((error) => {
          Modal.error({
            content: error.response.data
          })
          addProductFyGuids.value = []
          getSearch()
        })
    },
    onCancel() {}
  })
}
//添加体检产品--关闭modal框后清除数据
const handleCancel = () => {
  addProductFyGuids.value = []
  modalReset()
  getInquire()
}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
}

.box {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.select-box {
  width: 200px;
  margin-right: 40px;
  margin-top: 10px;
}

.select-box2 {
  width: 200px;
  margin-right: 40px;
}

.button-box {
  width: 440px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.mt5 {
  margin-top: 5px;
}

.add-box {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.red {
  color: red;
}

:deep(.ant-table-tbody) .ant-table-cell {
  padding: 10px 16px !important;
}
</style>
